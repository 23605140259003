<!-- <div id="flash-poster" [hidden]="!flashOn">
  <div (click)="acceptFlash()" class="cta-main flash-button"><strong>Continue</strong></div>
  <img src="./assets/images/queen_rip.jpg" />
</div> -->


<div *ngIf="dataLoaded && lib.isDefined(pages[setPage])" id="scrollwindow-off" [ngClass]="flashOn ? 'flash-on' : 'flash-off'">
  <div id="infotextcontainer"></div>


  <!-- <p>Scrolled: {{ getScrolled() }}% </p> -->

  <!-- Navigation Stats -->
  <div class="navigation-stats-panel hide">
      <!-- <p>You are here: {{ scrolledEl }} </p>
      <p>Scrolled: {{ getScrolled() }}% </p>
      <p>mouseY: {{mouseY}} mouseX: {{mouseX}}</p> -->
      <!-- <div>
        <svg id="progressCircle" class="progress-ring" width="50" height="50">
          <circle class="progress-ring__circle" stroke="#3ba439" stroke-width="2" fill="transparent" r="21" cx="25" cy="25"/>
        </svg>
        <input id="progressCircleInput" progressCircle [value]="getScrolled()" [mouseX]="mouseX" [mouseY]="mouseY" type="number" step="5" min="0" max="100" placeholder="progress" style="display:none" >
      </div> -->
  </div>

  <!-- Mobile Menu -->

  <div *ngIf="pages[setPage].group !== 'landing-page'" class="mobile-panel background-primary" [ngClass]="mobile_on ? 'mobile-on': 'mobile-off'" (click)="toggleMobileMenu()">
    <div class="mobile-menu-close-btn text-secondary pointer" (click)="toggleMobileMenu()">
      <div class="menu-icon svg float-right">
        <img src="./assets/images/info_white.svg" style="display:inline-block; width:40px;" />
      </div>
    </div>

    <div class="row abs fill-height" style="top:0px;left:0px">
      <div class="cols width-50 fill-height center">
        <div class="block center v-align2">
          <div class="row">
            <ul id="mainmenu-mobile" class="menu-mobile text-center">
              <li *ngFor="let menu of mainmenu; let mid = index" (click)="changePage(false, mid, [], $event,false)" class="menuitem {{menu.class}}" [ngClass]="menu.submenu && menu.submenu.length && !menu.hidesubmenu ? 'submenu-parent': ''">
                  <div class="menuname btn icon-arrow-down-green"> {{menu.name}}</div>
                    <ul *ngIf="menu.submenu &amp;&amp; !menu.hidesubmenu" id="mainmenu-mobile-submenu-mid" class="mobile-submenu">
                        <li *ngFor="let submenu of menu.submenu; let smid = index" (click)="changePage(false, mid, [smid], $event,submenu.path != '' ? submenu.path : false)" class="menuitem">
                            <div class="menuname btn outline-text">{{submenu.name}}</div>
                        </li>
                    </ul>
              </li>
            </ul>
            <p class="text-white" style="margin-top:0px">We are Graphic Impressions,<br>
              White Collar Factory, 1 Old Street Yard,London, EC1Y 8AF
              <!-- <span class="no-margin" [innerHTML]="details.address"></span> -->
            </p>
            <p class="no-margin text-secondary-section">
              <a href="mailto:{{details.email}}" class="bold" [innerHTML]="details.email"></a><br>
              <span class="bold">Call <span class="bold" [innerHTML]="details.phone"></span></span><br>
            </p>
          </div>
          <div class="row footer-icons social-icons v-align-bot">
            <p class="text-secondary">Or socialise with us:</p>
              <p class="block text-center center">
                <a href="https://www.instagram.com/wearegraphici/" target="_blank"><img src="assets/icons/icon_instagram_circle_secondary.svg" class="social-icon" /></a>
                <a href="https://twitter.com/wearegraphici" target="_blank"><img src="assets/icons/icon_twitter_circle_secondary.svg" class="social-icon" /></a>
                <a href="https://www.facebook.com/wearegraphici" target="_blank"><img src="assets/icons/icon_facebook_circle_secondary.svg" class="social-icon" /></a>
                <a href="https://www.linkedin.com/company/wearegraphici" target="_blank"><img src="assets/icons/icon_linkedin_circle_secondary.svg" class="social-icon" /></a>
              </p>
          </div>

        </div>
     </div>
    </div>






  </div>
  <div class="mobile-wash" [ngClass]="mobile_on ? 'mobile-on': 'mobile-off'" (click)="toggleMobileMenu(true)"></div>
  <!-- End of Mobile Menu -->

  <!--Side Navigation -->

  <!-- <comp-sidenav (callback)="handleCallback($event)"></comp-sidenav> -->

  <!-- End of Side Navigation -->

  <!-- Header -->
  <div id="header-wrap" [ngClass]="scrollpos > 100 ? 'scrolled': ''">
    <div id="header" class="header pad-horiz-40 v-align2">
      <div class="center rel">

            <div *ngIf="pages[setPage].group !== 'landing-page'" class="float-left block pointer" style="position: absolute;left: 0px;">
                <!-- <i class="fa fa-search" aria-hidden="true" style="color: white;border: solid 2px white;padding: 10px;"></i> -->
                <!-- <div class="mobile-menu-btn icon-container" (click)="toggleMobileMenu()">
                  <div class="grid-icon svg icon-menu"></div>
                </div> -->
                <img (click)="changePage('home', 0, [], $event,false)" src="./assets/images/logo_outline.svg" style="position:absolute; display:inline-block; width:40px;" [ngClass]="scrollpos > 100 ? 'opacity-0' : ''" />
                <img (click)="changePage('home', 0, [], $event,false)" src="./assets/images/logo_white_solid.svg" class="chase-logo-ampersand" [ngClass]="scrollpos < 100 ? 'opacity-0' : ''" />
                <img (click)="changePage('home', 0, [], $event,false)" src="./assets/images/gi-logo-title-white.svg" class="chase-logo-full"  [ngClass]="scrollpos > 100 || mobile_on == true ? 'opacity-0 fly-left' : ''" />
            </div>


            <div *ngIf="pages[setPage].group == 'landing-page'" class="center block pointer">

                <img (click)="changePage('home', 0, [], $event,false)" src="./assets/images/gi-logo-full-white.svg" class="landing-page-logo"  [ngClass]="scrollpos > 5 || mobile_on == true ? 'opacity-0 flyup4' : ''" />
            </div>

            <!-- <div class="logo inline-block" (click)="changePage(false, 0, [], $event,false)">

            <img src="/assets/images/chase-logo-primary.svg" style="display:inline-block; width:100%;" />
            </div> -->

          <div *ngIf="pages[setPage].group !== 'landing-page'" class="float-right" style="position: absolute;right: 0px;">
              <div>
                  <!-- <div>
                    <ul id="header-minimenu" class="tablet-hide list-horiz text-white">
                      <li class="bold" (click)="changePage('about-us', 0, [], $event,false)">About</li>
                      <li class="bold" (click)="changePage('our-work', 0, [], $event,false)">Work</li>
                      <li class="bold" (click)="changePage('our-work', 0, [], $event,false)">Services</li>
                      <li class="bold" (click)="changePage('contact', 0, [], $event,false)">Contact</li>
                    </ul>
                  </div> -->

                  <div class="icon-container  pointer">
                    <!-- <div (click)="toggleSearch()" class="menu-icon svg icon-search"></div> -->
                    <!-- <div (click)="toggleMobileMenu()" class="menu-icon svg icon-menu"></div> -->
                    <img (click)="toggleMobileMenu()" src="./assets/images/info_outline.svg" style="position:absolute; display:inline-block; width:40px;" [ngClass]="scrollpos > 100 ? 'opacity-0' : ''" />
                    <img (click)="toggleMobileMenu()" src="./assets/images/info_white_solid.svg" style="display:inline-block; width:40px;" [ngClass]="scrollpos < 100 ? 'opacity-0' : 'icon-shadow'" />
                  </div>

              </div>
          </div>


          <!-- Top Navigation -->


          <div *ngIf="showTopNavHover == true && pages[setPage].group == 'casestudy-page'" id="topNavigation" class="casestudy-page top-navigation" [ngClass]="topNavHover ? 'on' : ''" (mouseleave)="topNavHover=false">
            <div class="icon-wrap">
              <img [hidden]="scrollpos > 600" id="arrowUp" (click)="changePage('our-work')" (mouseenter)="topNavHover=true" src="./assets/icons/{{topNavHover ? 'arrow-up-white' : 'arrow-up-outline'}}.svg" />
              <img [hidden]="scrollpos < 600" id="arrowUpPrimary" (click)="changePage('our-work')" (mouseenter)="topNavHover=true"  src="./assets/icons/{{topNavHover ? 'arrow-up-white' : 'arrow-up-outline'}}.svg" />
            </div>
            <p [hidden]="!topNavHover" class="text-white" style="position:relative; top:-28px">Return to work examples</p>
          </div>

          <!-- End of top navigation -->
      </div>
    </div>
  </div>
  <!-- End of Header-->


  <div *ngIf="pages[setPage]" class="{{setPage}} {{pages[setPage].classes}} {{pages[setPage].group}}" class="scroll-content" [attr.scroll]="0">

<!-- Main Section -->
<div id="main" #main>

<!-- Hero -->
<div *ngIf="pages[setPage].header.type !== ''" class="container hero {{pages[setPage].header.classes}}" [ngStyle]="pages[setPage].header.src !== '' ? {'background-image': 'url('+ pages[setPage].header.src +')' } : {}" [ngClass]="loaded ? 'on': ''" #hero>

    <div *ngIf="pages[setPage].header.type == 'map'" class="container v-align">
      <div style="z-index:1" class="block full-width  abs v-align2  {{pages[setPage].header.blockclasses}}">
        <div class="box {{pages[setPage].header.boxclasses}}" style="margin-top: -60px!important;">
          <div>

            <h1 class="line1 {{pages[setPage].header.line1.classes}}" [innerHTML]="pages[setPage].header.line1.value"></h1>
            <p class="line2 {{pages[setPage].header.line2.classes}}" [innerHTML]="pages[setPage].header.line2.value"></p>
            <!-- <p class="line3 {{pages[setPage].header.line3.classes}}" [innerHTML]="pages[setPage].header.line3.value"></p> -->
            <!-- Hero CTA -->
            <div *ngIf="pages[setPage].group == 'main-page'" #heroscroll id="hero-scroll-cta" class="pointer text-white flyup2 center table title-button" (click)="scrollToMain()">
               <!-- <div class="icon-arrow-down anim-bounce"></div> -->
               <!-- <div class="cta-wrap">{{pages[setPage].header.line3.value.length > 0 ? pages[setPage].header.line3.value : 'View >'}}</div> -->
               <img src="./assets/icons/arrow-down-outline.svg" width="30" height="30" class="main-icon" style="display:block" />
               <img src="./assets/icons/arrow-down.svg" width="30" height="30" class="hover-icon" style="display:block" />
            </div>
          </div>
        </div>
      </div>
      <iframe [src]="pages[setPage].header.src | safe : 'url'" width="100%" height="100%" frameborder="0" style="z-index:0;top:0;border:0;position:absolute;opacity:0.3" ></iframe>
    </div>


    <div *ngIf="pages[setPage].header.type != 'map'" class="container v-align">
      <div *ngIf="pages[setPage].header.type == 'hero'" class="block full-width {{pages[setPage].header.blockclasses}}">
        <div class="box opacity-09 {{pages[setPage].header.boxclasses}}" [ngClass]="scrollpos > 5 && pages[setPage].group == 'landing-page' ? 'opacity-0 flyup4' : ''">
          <div>
            <!-- <h1 *ngIf="setPage == 'home'" class="line1 animate-text {{pages[setPage].header.line1.classes}}"></h1> -->
            <h1 *ngIf="setPage != 'home'" class="line1 {{pages[setPage].header.line1.classes}}" [innerHTML]="pages[setPage].header.line1.value"></h1>

            <p class="line2 {{pages[setPage].header.line2.classes}}" [innerHTML]="pages[setPage].header.line2.value"></p>
            <!-- <p class="line3 {{pages[setPage].header.line3.classes}}" [innerHTML]="pages[setPage].header.line3.value"></p> -->
            <!-- Hero CTA -->
            <!-- <div *ngIf="pages[setPage].group == 'main-page'" #heroscroll id="hero-scroll-cta" class="pointer text-white flyup2 center table title-button" (click)="scrollToMain()">
               <img src="./assets/icons/arrow-down-outline.svg" width="30" height="30" class="main-icon center" style="display:block" />
               <img src="./assets/icons/arrow-down.svg" width="30" height="30" class="hover-icon center" style="display:block" />
               <p class="center text-white hover-icon full-width table rel" style="top:30px" >Go straight to {{setPage | format : 'replaceCharacters'}}</p>
             </div> -->

             <div *ngIf="setPage=='contact'" class="text-white-section text-white">
               <!-- <br><br>
               We are always happy for you to get in touch:<br><br>
               <a href="hello@graphicimpressions.co.uk">hello@graphicimpressions.co.uk</a><br>
               +44 (0) 207 253 5444<br><br>

               <div class="row footer-icons social-icons v-align-bot">
                 <img src="assets/icons/icon_youtube.svg" class="social-icon">
                 <img src="assets/icons/icon_instagram.svg" class="social-icon">
                 <img src="assets/icons/icon_twitter.svg" class="social-icon">
                 <img src="assets/icons/icon_facebook.svg" class="social-icon">
               </div>

               <br> Please fill out the form below: -->


              </div>
          </div>
        </div>
      </div>

      <div *ngIf="pages[setPage].header.type == 'carousel'" class="table fill-height full-width background-primary text-white">

        <ng-lottie id="video_title" [options]="options" (animationCreated)="animationCreated($event)" class="lottie"></ng-lottie>

        <video id="vid" autoplay muted loop class="hero-video" oncanplay="this.play()" onloadedmetadata="this.muted = true" preload="auto">
          <source src="./assets/videos/gi-background-only.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
          <!-- <comp-carousel #comp [data]="pages[setPage].fields[0].value" [thumbnails]="'off'" [navigation]="false" [theme]="'hero'" [slideshow]="true" [classes]=""></comp-carousel> -->
      </div>
   </div>

    <div *ngIf="windowWidth > 900" [ngClass]="pages[setPage].group == 'main-page' && pages[setPage].alias !== 'home'  ? 'show': 'hide'" id="parallax-scene" class="parallax-scene" dataParallaxDir ></div>
</div>
<!-- End of Hero -->

<!-- Main section -->
<div class="section" id="main-section" style="margin-top: 0px;" #mainsection>
  <div class="container">

    <div *ngIf="pages[setPage].group == 'main-page' || pages[setPage].group == 'landing-page'">
  <div *ngFor="let row of pages[setPage].rows; let ridx = index" class=" mainrow row row{{ridx+1}} {{row.params.classes}}" [attr.loaded]="false" [attr.id]=" row.params.ido && row.params.ido !== '' ? row.params.ido : 'row'+(ridx+1)"  [attrDir]="row.params.attributes" [noDir]="true" [ngClass]="[lib.lazyLoad(r) == true ? 'inview': '', lib.isScrolled(r,scrolledEl) == true ? 'isscrolled': '']" #r>
    <div *ngIf="r.attributes.loaded.value == 'true'" class="{{row.params.innerclasses}}">
      <div *ngFor="let col of row.cols; let cidx = index" class="cols col-{{row.cols.length}} col{{cidx+1}} {{col.params.outerclasses}}" >
        <div class="block {{col.params.classes}}" [ngClass]="col.params.padding == false || col.params.padding == 'false' ? 'no-padding' : 'padding'" [attrDir]="col.params.attributes" [t]="this">
          <div *ngFor="let el of col.content; let eid = index" class="element" id="el-{{ridx}}-{{cidx}}-{{eid}}" [ngClass]="el.elclasses? el.elclasses : ''">
             <child-template [el]="el" [eid]="eid" [cid]="cidx" [rid]="ridx" [pages]="pages" [setPage]="setPage" [get]="get" [parent]="t"  (callback)="handleCallback($event)"></child-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <!--Case study template -->
  <div *ngIf="pages[setPage].group == 'casestudy-page'" class="container center">
    <casestudy-template [content]="pages[setPage]" [t]="t" [get]="get" (callback)="handleCallback($event)"></casestudy-template>
  </div>

  <!--News template -->
  <div *ngIf="pages[setPage].group == 'news-page'" class="container center">
    <news-template [content]="pages[setPage]" [t]="t" [get]="get" (callback)="handleCallback($event)"></news-template>
  </div>

  <!-- sitemap menu -->
  <div class="row">
    <div class="col">
      <div class="element">
        <div *ngIf="setPage == 'sitemap'" class="pad-20">
              <ul id="mainmenu" class="sitemap-menu">
                <li *ngFor="let menu of mainmenu; let mid = index" (click)="changePage(false, mid, [], $event)" class="sitemap-menuitem">
                    <div class="menuname bold menulink">{{menu.name}}</div>
                    <ul *ngIf="menu.submenu &amp;&amp; menu.submenu.length" class="sitemap-menu">
                        <li *ngFor="let submenuitem of menu.submenu; let smid = index" (click)="changePage(false, mid, [smid], $event)" class="sitemap-menuitem">
                            <div class="sitemap-menuname menulink"><div class="label">{{submenuitem.name}}</div></div>
                            <ul *ngIf="submenuitem.submenu &amp;&amp; submenuitem.submenu.length" class="sitemap-submenu sitemap-submenu1">
                              <li *ngFor="let submenuitem2 of submenuitem.submenu; let smid2 = index" (click)="changePage(false, mid, [smid,smid2], $event)" class="sitemap-menuitem">
                                  <div class="sitemap-menuname menulink">
                                    <div class="label">{{submenuitem2.name}}</div>
                                  </div>
                              </li>
                            </ul>
                        </li>
                    </ul>
                </li>
              </ul>
         </div>
      </div>
    </div>
  </div>

  </div>
</div>
<!-- End of Main Content -->


</div>
<!-- End of Main Section -->


<!-- Sitemap -->
<div id="footer" class="container footer background-grey text-white panel">
    <div class="row">
        <div class="element pad-horiz-40 maxwidth center">
          <div class="cols col-2 align-left width-70">


              <!-- <div class="row">

                <div class="cols col-3">
                  <h5>Main Menu</h5>
                  <p *ngFor="let fmenu of (footermenu ? footermenu.slice(0,5): [])" (click)="changePage(fmenu.alias, 0, [], $event)" class="menulink footermenu-item no-margin {{fmenu.class}}">
                    {{fmenu.name}}
                  </p>
                </div>

                <div class="cols col-3">
                  <h5>Sectors</h5>
                  <p *ngFor="let fmenu of (footermenu ? footermenu.slice(5,9): [])" (click)="changePage(fmenu.alias, 0, [], $event)" class="menulink footermenu-item no-margin {{fmenu.class}}">
                    {{fmenu.name}}
                  </p>
                </div>

                <div class="cols col-3">
                  <h5 class="tablet-hide">&nbsp;</h5>
                  <p *ngFor="let fmenu of (footermenu ? footermenu.slice(9,12): [])" (click)="changePage(fmenu.alias, 0, [], $event)" class="menulink footermenu-item no-margin {{fmenu.class}}">
                    {{fmenu.name}}
                  </p>
                </div>

            </div> -->

            <div class="row">

              <div class="cols col-3">
                <h6>Strategy</h6>
                <p *ngFor="let fmenu of (footermenu ? footermenu.slice(12,14): [])" (click)="changePage(fmenu.alias, 0, [], $event, fmenu.get)" class="menulink footermenu-item no-margin {{fmenu.class}}">
                  {{fmenu.name}}
                </p>
              </div>

              <div class="cols col-3">
                <h6>Design Services</h6>
                <p *ngFor="let fmenu of (footermenu ? footermenu.slice(14,18): [])" (click)="changePage(fmenu.alias, 0, [], $event, fmenu.get)" class="menulink footermenu-item no-margin {{fmenu.class}}">
                  {{fmenu.name}}
                </p>
              </div>

              <div class="cols col-3">
                <h6>Digital Services</h6>
                <p *ngFor="let fmenu of (footermenu ? footermenu.slice(18,19): [])" (click)="changePage(fmenu.alias, 0, [], $event, fmenu.get)" class="menulink footermenu-item no-margin {{fmenu.class}}">
                  {{fmenu.name}}
                </p>
              </div>

            </div>

            <div class="row pad-top-40">

              <div class="cols col-3">
                <h6>Imagery & Animation</h6>
                <p *ngFor="let fmenu of (footermenu ? footermenu.slice(19,23): [])" (click)="changePage(fmenu.alias, 0, [], $event, fmenu.get)" class="menulink footermenu-item no-margin {{fmenu.class}}">
                  {{fmenu.name}}
                </p>
              </div>

              <div class="cols col-3">
                <h6>Content & Delivery</h6>
                <p *ngFor="let fmenu of (footermenu ? footermenu.slice(23,26): [])" (click)="changePage(fmenu.alias, 0, [], $event, fmenu.get)" class="menulink footermenu-item no-margin {{fmenu.class}}">
                  {{fmenu.name}}
                </p>
              </div>

              <div class="cols col-3">

              </div>

            </div>


          </div>
          <div class="cols col-2 text-left float-right width-30">
            <div class="table footer-section">
              <!-- <div class="row">
                <p class="no-margin" [innerHTML]="details.address"></p>
                <br>
                <p class="no-margin">
                  <a href="mailto:{{details.email}}" class="bold" [innerHTML]="details.email"></a><br>
                  <span [innerHTML]="details.phone"></span><br>
                </p>
                <br><br>
              </div>
              <div class="row footer-icons social-icons v-align-bot">
                <img src="assets/icons/icon_facebook.svg" class="social-icon" />
                <img src="assets/icons/icon_instagram.svg" class="social-icon" />
                <img src="assets/icons/icon_twitter.svg" class="social-icon" />
                <img src="assets/icons/icon_youtube.svg" class="social-icon" />
              </div> -->




                <iframe class="animationframe" width="400" height="400" ></iframe>



            </div>
         </div>
        </div>
    </div>
</div>
<!-- End of Sitemap -->
<div id="footer-end" class="container footer text-left background-darkgrey text-white panel-top">
  <div class="row footer-stick" [ngClass]="[isFooterStick ? 'on' : '', isBottom == true ? 'hide' : '']">
    <div class="element pad-horiz-40 maxwidth center text-left col-4-2">
      <div class="cols col-4">
        <h6><a href="home">Home</a></h6>
        <h6><a href="our-work">Our work</a></h6>
        <h6><a href="about-us">About us</a></h6>
        <h6><a href="blog">What we think</a></h6>
        <h6><a href="contact">Get in touch</a></h6>
      </div>
      <div class="cols col-4">
        <h6>We are ready</h6>
        <p>
          Get in touch with your project and see how we can help <a href="mailto:hello@graphicimpressions.co.uk">hello@graphicimpressions.co.uk</a>
        </p>
        <div class="social-icons-wrap">
          <a href="https://www.facebook.com/wearegraphici" target="_blank"><img src="assets/icons/icon_facebook_circle_secondary.svg" class="social-icon" /></a>
          <a href="https://www.instagram.com/wearegraphici/" target="_blank"><img src="assets/icons/icon_instagram_circle_secondary.svg" class="social-icon" /></a>
          <a href="https://twitter.com/wearegraphici" target="_blank"><img src="assets/icons/icon_twitter_circle_secondary.svg" class="social-icon" /></a>
          <a href="https://www.linkedin.com/company/wearegraphici" target="_blank"><img src="assets/icons/icon_linkedin_circle_secondary.svg" class="social-icon" /></a>
        </div>
        <p>
          Are you ready for your next step?
          <a href="mailto:jobs@graphicimpressions.co.uk">jobs@graphicimpressions.co.uk</a>
        </p>
      </div>
      <div class="cols col-4 footer-icons social-icons">
        <h6>We are certified</h6>
        <p style="max-width:250px;">At Graphic Impressions we strive to ensure the work we do is consistently to the highest standards. This is why we are ISO 27001 certified</p>
        <div >
          <img src="./assets/certifications/ISO-9001-2015-vertical-grey.svg" width="53.5" height="auto" class="inline-block v-align-top marg-right-10" />
          <img src="./assets/certifications/ISO-14001-2015-vertical-grey.svg" width="53.5" height="auto" class="inline-block v-align-top marg-right-10" />
          <img src="./assets/certifications/ISO-27001-2013-vertical-grey.svg" width="50" height="auto" class="inline-block v-align-top" />
        </div>
      </div>
      <div class="cols col-4 text-white">
        <h6>We are legal</h6>
        <a href="cookies">Cookies</a><br>
        <a href="policies">Policies</a><br>
        <a href="terms">Terms and Conditions</a><br>
        <a href="company-information">Company Information</a><br>
        <br>

        <p>
          Graphic Impressions,<br>
          White Collar Factory, 1 Old Street Yard,<br>
          London EC1Y 8AF<br>
          Call +44 (0) 207 253 5444
        </p>
      </div>
  </div>
  </div>
  <div class="row is-scrolled footer-info">
    <div class="element pad-horiz-40 maxwidth center text-left col-4-2">
      <div class="cols col-4 c1">
        <h6><a href="home">Home</a></h6>
        <h6><a href="our-work">Our work</a></h6>
        <h6><a href="about-us">About us</a></h6>
        <h6><a href="blog">What we think</a></h6>
        <h6><a href="contact">Get in touch</a></h6>
      </div>
      <div class="cols col-4 c2">
        <div class="wrap">
          <h6>We are ready</h6>
          <p>
            Get in touch with your project and see how we can help <a href="mailto:hello@graphicimpressions.co.uk">hello@graphicimpressions.co.uk</a>
          </p>

          <div class="social-icons-wrap">
            <a href="https://www.facebook.com/wearegraphici" target="_blank"><img src="assets/icons/icon_facebook_circle_secondary.svg" class="social-icon" /></a>
            <a href="https://www.instagram.com/wearegraphici/" target="_blank"><img src="assets/icons/icon_instagram_circle_secondary.svg" class="social-icon" /></a>
            <a href="https://twitter.com/wearegraphici" target="_blank"><img src="assets/icons/icon_twitter_circle_secondary.svg" class="social-icon" /></a>
            <a href="https://www.linkedin.com/company/wearegraphici" target="_blank"><img src="assets/icons/icon_linkedin_circle_secondary.svg" class="social-icon" /></a>
          </div>

          <p>
            Are you ready for your next step?
            <a href="mailto:jobs@graphicimpressions.co.uk">jobs@graphicimpressions.co.uk</a>
          </p>

        </div>
      </div>
      <div class="cols col-4 c3">
        <h6>We are certified</h6>
        <p style="max-width:250px;">At Graphic Impressions we strive to ensure the work we do is consistently to the highest standards. This is why we are ISO 27001 certified</p>
        <div >
          <img src="./assets/certifications/ISO-9001-2015-vertical-grey.svg" width="53.5" height="auto" class="inline-block v-align-top marg-right-10" />
          <img src="./assets/certifications/ISO-14001-2015-vertical-grey.svg" width="53.5" height="auto" class="inline-block v-align-top marg-right-10" />
          <img src="./assets/certifications/ISO-27001-2013-vertical-grey.svg" width="50" height="auto" class="inline-block v-align-top" />
        </div>
      </div>

      <div class="cols col-4 text-white c6">
        <h6>We are legal</h6>
        <a href="cookies">Cookies</a><br>
        <a href="policies">Policies</a><br>
        <a href="terms">Terms and Conditions</a><br>
        <a href="company-information">Company Information</a><br>
        <br>

        <p>
          Graphic Impressions,<br>
          White Collar Factory, 1 Old Street Yard,<br>
          London EC1Y 8AF<br>
          Call +44 (0) 207 253 5444
        </p>
      </div>
  </div>
  </div>
  <!--
  <div class="row marg-bot-60">
    <div class="certified-wrap element pad-horiz-40 text-left center maxwidth">
      <div class="cols col-3 tablet-hide" style="width:150px;">
        &nbsp;
      </div>
      <div class="cols col-3 width-50">
          <h6>We are certified</h6>
          <p>At Graphic Impressions we strive to ensure the work we do is consistently to the highest standards. This is why we are ISO 27001 certified</p>
      </div>
      <div class="cols col-3">
        <img src="./assets/certifications/ISO-9001-2015-vertical-grey.svg" width="53.5" height="auto" class="inline-block v-align-top marg-right-10" />
        <img src="./assets/certifications/ISO-14001-2015-vertical-grey.svg" width="53.5" height="auto" class="inline-block v-align-top marg-right-10" />
        <img src="./assets/certifications/ISO-27001-2013-vertical-grey.svg" width="50" height="auto" class="inline-block v-align-top" />
      </div>
    </div>
  </div> -->



</div>



<!-- Modal -->
<app-modal [(visible)]="showModal" (callback)="handleCallback($event)"  [modal_content]="modal_content" [modal_key]="modal_key">
  <!-- <button (click)="showModal = !showModal" class="btn">Close</button> -->
</app-modal>

<!--Search -->
<comp-search [(visible)]="showSearch" (visibleChange)="toggleSearch()" (callback)="handleCallback($event)"  [pages]="pages"></comp-search>

  </div> <!--end of scroll content-->

  <div class="row text-white-section pad-40 is-scrolled footer-bar {{pages[setPage].group}}" style="z-index:3">
    <div class="maxwidth row center">
      <div class="float-left inline">© 2024 graphic impressions</div> <div class="abs center text-center mobile-hide"><a href="mailto:hello@graphicimpressions.co.uk">hello@graphicimpressions.co.uk</a></div> <div [ngClass]="isBottom == true ? 'hide2' : 'show'" (click)="isFooterStick = !isFooterStick" class="inline float-right footer-stick-btn"><img src="./assets/icons/{{isFooterStick ? 'minus' : 'plus'}}.svg" width="20" height="20" class="footer-stick-icon" /></div>
    </div>
  </div>

</div><!--if data loaded and setPage defined-->



<router-outlet></router-outlet>
